import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '@context';

import Meta from '@components/Meta';

import styles from './index.module.scss';
import CTA from '@components/CTA';

const CareerPage = ({ pageContext }) => {
  const { actions } = useContext(GlobalContext);

  const {
    title,
    description,
    duties,
    qualifications,
    optionalQualifications,
    superQualifications,
  } = pageContext;
  const emailSubject = encodeURIComponent(`I’d like to be your new ${title}`);

  useEffect(() => {
    actions.setPageLoaded(true);
  }, []);

  return (
    <>
      <Meta title={`OddCommon | Careers • ${title}`} />
      <div className={styles.career}>
        <section className={styles.intro}>
          <h1 className={styles.headline}>{title}</h1>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          <p>
            <CTA
              href={`mailto:hello@oddcommon.com?subject=${emailSubject}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.apply}
            >
              Reach Out
            </CTA>
          </p>
        </section>
        <hr />
        <section>
          <div className={styles['side-by-side']}>
            <h3>Key Duties</h3>
            <div className={styles.list} dangerouslySetInnerHTML={{ __html: duties }} />
          </div>
          <div className={styles['side-by-side']}>
            <h3>You Are:</h3>
            <div className={styles.list} dangerouslySetInnerHTML={{ __html: qualifications }} />
          </div>
          <div className={styles['side-by-side']}>
            <h3>Good To Have</h3>
            <div
              className={styles.list}
              dangerouslySetInnerHTML={{ __html: optionalQualifications }}
            />
          </div>
          <div className={styles['side-by-side']}>
            <h3>Really Nice To Have</h3>
            <div
              className={styles.list}
              dangerouslySetInnerHTML={{ __html: superQualifications }}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default CareerPage;
